import { graphql } from 'gatsby'
import React from 'react'
import {Container} from 'reactstrap'
import Layout from '../components/Layout/Layout'


class FaqPage extends React.Component {
    render () {
        const { localizations } = this.props.pageContext
        let data = this.props.data.contentfulFaqPage

        let faqNodes = []
        for (let faq of data.faqs) {
            let html = faq.answer.childMarkdownRemark.html
            faqNodes.push(
                <div key={faq.id} className="mb-5">
                    <h3 className="mb-3">{localizations['faq']['question-letter']}: {faq.question.question}</h3>
                    <div dangerouslySetInnerHTML={{__html: html}}/>
                </div>
            )
        }

        return <Layout pageContext={this.props.pageContext}>
            <Container>
                <h1 className="mt-5 mb-4">{data.title}</h1>

                {faqNodes}

            </Container>
        </Layout>
    }
}

export default FaqPage

export const pageQuery = graphql`
    query FAQ($locale: String!) {
        contentfulFaqPage(node_locale: {eq: $locale}) {
            title
            faqs {
                id
                question {
                    question
                }
                answer {
                    answer
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }
`
